<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="$userInfo.isSupportUser"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <orm-message-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tabs is-toggle is-toggle-rounded">
              <ul>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.UnRead }">
                  <a @click="tabClicked(messageStatusTypes.UnRead)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email mdi-18px" />
                    </span>
                    <span>
                      <span :class="unreadOffset">Unread</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.UnRead ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.UnRead)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Read }">
                  <a @click="tabClicked(messageStatusTypes.Read)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email-open mdi-18px" />
                    </span>
                    <span>
                      <span :class="readOffset">Read</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Read ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Synced }">
                  <a @click="tabClicked(messageStatusTypes.Synced)">
                    <span class="icon is-small">
                      <i class="mdi mdi-sync mdi-18px" />
                    </span>
                    <span>
                      <span :class="syncedOffset">Synced</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Synced ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.ToSend }">
                  <a @click="tabClicked(messageStatusTypes.ToSend)">
                    <span class="icon is-small">
                      <i class="mdi mdi-send mdi-18px" />
                    </span>
                    <span>
                      <span :class="toSendOffset">To Send</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.ToSend ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.ToSend)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Sent }">
                  <a @click="tabClicked(messageStatusTypes.Sent)">
                    <span class="icon is-small">
                      <i class="mdi mdi-checkbox-marked-circle mdi-18px" />
                    </span>
                    <span>
                      <span :class="sentOffset">Sent</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Sent ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.SyncFailed }">
                  <a @click="tabClicked(messageStatusTypes.SyncFailed)">
                    <span class="icon is-small">
                      <i class="mdi mdi-alert mdi-18px" />
                    </span>
                    <span>
                      <span :class="syncFailedOffset">Sync Failed</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.SyncFailed ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.SyncFailed)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Deleted }">
                  <a @click="tabClicked(messageStatusTypes.Deleted)">
                    <span class="icon is-small">
                      <i class="mdi mdi-delete mdi-18px" />
                    </span>
                    <span>
                      <span :class="deletedOffset">Deleted</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Deleted ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
              </ul>
              <div class="tags has-addons"
                style="margin-left: auto">
                <span class="tag is-dark">Last fetched</span>
                <span v-if="!isTableLoading"
                  class="tag is-success">{{ $filters.formatDateTimeLocale(new Date(), $userInfo.locale) }}</span>
              </div>
            </div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.messageId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  <router-link v-if="canEditMessage"
                    :to="{ path: entity.messageId }"
                    append>
                    {{ entity.messageType }}
                  </router-link>
                  <span v-else>{{ entity.messageType }}</span>
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.qdeStatus }}
                </td>
                <td class="has-text-centered"
                  :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.ormMessageNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.regoNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.claimNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.claimType }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.customerName }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.mobilePhone }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.uid }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.quoteNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.vehicleDescription }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ $filters.formatDateTimeLocale(entity.createdDate, $userInfo.locale) }}
                </td>
                <td class="is-content-width">
                  <div class="has-text-centered">
                    <router-link v-if="canEditMessage"
                      :to="{ path: entity.messageId }"
                      class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="View/Edit XML"
                      append>
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </router-link>
                    <a v-if="canLoadMessage(entity.messageTypeId)"
                      class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Load Message"
                      @click="load(index)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-download mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageRead"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Read"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Read)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email-open mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canPrintMessage(entity.messageTypeId)"
                      class="button is-black is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Print Message"
                      @click="print(entity.messageId, entity.messageTypeId)">
                      <span class="icon is-medium ">
                        <i class="mdi mdi-printer mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageUnread"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Unread"
                      @click="markMessageAsUnread(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canResendMessage(entity.statusId)"
                      class="button is-link is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Resend Message"
                      @click="markMessageAsToSend(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-send mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canDeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Delete Message"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Deleted)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canUndeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      :data-tooltip="[entity.messageDirection === 0 ? 'Restore to Read' : 'Restore to Sent']"
                      @click="unDeleteMessage(entity.messageId, entity.messageDirection)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete-restore mdi-24px" />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <load-quote-modal :active.sync="isLoadQuoteModalActive"
      @close="closeModal()"
      @previous="previous()"
      @next="next()"
      @load="loadMessage()"
      :show-icon="false"
      :previous-disabled="currentStep === steps.CreateAssign.id"
      :next-disabled="currentStep === lastStep.id"
      :continue-disabled="!enableContinue">
      <p slot="text-title">ORM Load Quote</p>
      <div slot="text-content">
        <load-quote-steps v-model="loadOptions"
          :current-step="currentStep"
          :steps="steps"
          ecom-type="orm" />
      </div>
    </load-quote-modal>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="gotoQuote(true)"
      @cancel="gotoQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Go to Quote</p>
      <p slot="text-content">
        <span v-if="savedLoadOptions && savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.QML_RequestForQuote">Quote created</span>
        <span v-else-if="savedLoadOptions && !savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.QML_RequestForQuote">Quote assigned</span>
        <span v-else>Message loaded</span>
        successfully. Go to quote
        <span class="has-text-primary has-text-weight-bold">{{ `${quoteNo}` }}</span> now?
      </p>
    </confirm-modal>
    <confirm-modal v-if="selectedMessage"
      :active.sync="isOrmMessageNoModalActive"
      @ok="overwriteOrmMessageNo(true)"
      @cancel="overwriteOrmMessageNo(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">ORM MessageNo Conflict</p>
      <div slot="text-content">
        <p>
          Received
          <span class="has-text-primary has-text-weight-bold">MessageNo</span> is different from expected MessageNo
        </p>
        <br>
        <p style="text-indent: 40px">
          Received:
          <span class="has-text-primary has-text-weight-bold">{{ selectedMessage.ormMessageNo }}</span>
        </p>
        <p style="text-indent: 40px">
          Expected:
          <span class="has-text-primary has-text-weight-bold">{{ selectedMessage.expectedOrmMessageNo }}</span>
        </p>
        <br>
        <p>
          Load and overwrite MessageNo?
        </p>
      </div>
    </confirm-modal>
    <confirm-modal :active.sync="isMessageOperationConfirmModalActive"
      @ok="changeMessageStatus(selectedMessageId, messageOperationTypeId)"
      @cancel="isMessageOperationConfirmModalActive = false"
      ok-text="Yes">
      <p slot="text-title">ORM Message</p>
      <div slot="text-content">
        <span>Message will be moved to</span>
        <span class="has-text-weight-bold">
          {{ getFolderName(messageOperationTypeId) }}.
        </span><br>
        <span>Are you sure?</span>
      </div>
    </confirm-modal>
    <confirm-modal
      :active.sync="isEarlierMessageModalActive"
      @ok="load(minimalMessageNoIndex)"
      @cancel="isEarlierMessageModalActive = false"
      ok-text="Procced">
      <p slot="text-title">Earlier ORM Message Exists</p>
      <div slot="text-content">
        <span>Please load an earlier message type <span v-if="entitySummaries.length"
          class="has-text-weight-bold">{{ entitySummaries[minimalMessageNoIndex].messageType }}</span></span>
        <br>
        <br>
        <span>Do you want to continue?</span>
      </div>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import OrmMessageListSideMenu from './OrmMessageListSideMenu'
import OrmMessageRoutes from './route-types'
import BulmaTable from '@/components/BulmaTable'
import OrmMessageService from './OrmMessageService'
import { Columns } from './columns'
import { MessageTypes, MessageStatusTypes, RemarkTypes } from '@/enums'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import { LoadQuoteModal, LoadQuoteSteps, CustomerOptionTypes, Steps } from '@/components/LoadQuoteSteps'
import Guid from '@/components/Guid'
import { ConfirmModal } from '@/components/BulmaModal'
import OrmMessageValidation from './OrmMessageValidation'
import _cloneDeep from 'lodash/cloneDeep'
import NavService from '@/components/NavService'
import { CompanyService } from '@/services'
import QuoteRoutes from '@/views/quote/route-types'
import StoreUtil from '@/store/utils'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'

export default {
  name: 'OrmMessageListView',
  components: {
    AppDetailHeader,
    OrmMessageListSideMenu,
    LoadQuoteModal,
    BulmaTable,
    LoadQuoteSteps,
    ConfirmModal
  },
  mixins: [OrmMessageValidation, DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null
  },
  data() {
    return {
      // quoteEntity: null,
      isTableLoading: false,
      isEarlierMessageModalActive:false,
      minimalMessageNoIndex:0,
      entitySummaries: {},
      countSummaries: null,
      totalRows: 0,
      filter: {
        quoteNo: '',
        rego: '',
        claimNo: '',
        messageTypeId: 0,
        messageStatusId: MessageStatusTypes.UnRead,
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18,
        customerNameOrContactNo: ''
      },
      selectedRow: null,
      isLoadQuoteModalActive: false,
      currentStep: Steps.CreateAssign.id,
      loadOptions: {
        messageId: Guid.empty(),
        messageTypeId: MessageTypes.QML_RequestForQuote,
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        customerId: Guid.empty(),
        originalCustomerId: Guid.empty(),
        customerOption: CustomerOptionTypes.Create,
        title: '',
        customerName: '',
        firstname: '',
        lastname: '',
        isPerson: false,
        regoNo: '',
        vehicleId: Guid.empty(),
        isOverwriteVehicle: false,
        isOverwriteOrmMessageNo: false
      },
      vehicleExists: false,
      steps: Steps,
      isConfirmModalActive: false,
      isOrmMessageNoModalActive: false,
      isMessageOperationConfirmModalActive: false,
      messageOperationTypeId: '',
      quoteNo: '',
      quoteId: Guid.empty(),
      expectedOrmMessageNo: 0,
      selectedMessage: null,
      savedLoadOptions: null,
      autoSyncStatus: 0,
      unreadOffset: 'has-badge-child-offset2',
      readOffset: 'has-badge-child-offset2',
      toSendOffset: 'has-badge-child-offset2',
      sentOffset: 'has-badge-child-offset2',
      deletedOffset: 'has-badge-child-offset2',
      syncFailedOffset: 'has-badge-child-offset2',
      syncedOffset: 'has-badge-child-offset2'
    }
  },
  computed: {
    columns() {
      return Columns
    },
    messageStatusTypes() {
      return MessageStatusTypes
    },
    messageTypes() {
      return MessageTypes
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    canEditMessage() {
      return this.$user.info.isSupportUser
    },
    canMarkMessageRead() {
      return this.filter.messageStatusId === MessageStatusTypes.UnRead
    },
    canMarkMessageUnread() {
      return this.filter.messageStatusId === MessageStatusTypes.Read ||
        this.filter.messageStatusId === MessageStatusTypes.SyncFailed ||
        this.filter.messageStatusId === MessageStatusTypes.Synced
    },
    canDeleteMessage() {
      // Refs #2213
      return this.filter.messageStatusId !== MessageStatusTypes.Deleted && this.$user.info.isSupportUser
    },
    canUndeleteMessage() {
      return this.filter.messageStatusId === MessageStatusTypes.Deleted && this.$user.info.isSupportUser
    },
    lastStep() {
      if (this.steps.OverwriteVehicle.enabled) {
        return this.steps.OverwriteVehicle
      } else {
        return this.steps.UpdateCustomer
      }
    },
    enableContinue() {
      return this.lastStep.id === this.currentStep
    }
  },
  watch: {
    'loadOptions.firstname': function (newVal, oldVal) {
      this.loadOptions.customerName = `${this.loadOptions.lastname}, ${this.loadOptions.firstname}`
    },
    'loadOptions.lastname': function (newVal, oldVal) {
      this.loadOptions.customerName = `${this.loadOptions.lastname}, ${this.loadOptions.firstname}`
    }
  },
  created() {
    this.getEntitySummaries()
    this.getCountSummaries()
    this.GetAutoSyncStatus()
    // this.quoteEntity = await OrmMessageService.getQuoteEntity('87EEDFF3-1F83-4536-A448-380A8069D275')
    // OrmMessageService.auditTrailTest()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.quoteNo = ''
      this.filter.rego = ''
      this.filter.claimNo = ''
      this.filter.messageTypeId = 0
      this.filter.sortColumn = Columns[Columns.length - 2].name
      this.filter.sortOrder = Columns[Columns.length - 2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.filter.customerNameOrContactNo = ''
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    tabClicked(messageSatusId) {
      if (this.filter.messageStatusId !== messageSatusId) {
        this.filter.messageStatusId = messageSatusId
        this.filter.pageIndex = 1
        this.selectedRow = null
        this.persistFilter()
        this.getEntitySummaries()
        this.getCountSummaries()
      }
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await OrmMessageService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async GetAutoSyncStatus() {
      let companyExternalSettings = await CompanyService.getCompanyExternalSetting('ORM')
      this.autoSyncStatus = Number(companyExternalSettings.property4)
    },
    async getCountSummaries() {
      this.countSummaries = await OrmMessageService.getCountSummaries()
    },
    getCount(messageStatusId) {
      if (this.countSummaries) {
        const summary = this.countSummaries.find((c) => c.statusId === messageStatusId)
        let count = 0
        if (messageStatusId === MessageStatusTypes.ToSend) {
          const failedSummary = this.countSummaries.find((c) => c.statusId === MessageStatusTypes.Failed)
          count = summary.messageCount + failedSummary.messageCount
        } else {
          count = summary.messageCount
        }
        this.setBadgeOffset(messageStatusId, count)
        return count
      }
    },
    setBadgeOffset(messageStatusId, count) {
      let offset = 'has-badge-child-offset2'
      if (count >= 10 && count <= 99) {
        offset = 'has-badge-child-offset3'
      } else if (count >= 100 && count <= 999) {
        offset = 'has-badge-child-offset4'
      } else if (count >= 1000 && count <= 9999) {
        offset = 'has-badge-child-offset5'
      } else if (count >= 10000) {
        offset = 'has-badge-child-offset6'
      }
      switch (messageStatusId) {
        case MessageStatusTypes.UnRead:
          this.unreadOffset = offset
          break
        case MessageStatusTypes.Read:
          this.readOffset = offset
          break
        case MessageStatusTypes.ToSend:
          this.toSendOffset = offset
          break
        case MessageStatusTypes.Sent:
          this.sentOffset = offset
          break
        case MessageStatusTypes.Deleted:
          this.deletedOffset = offset
          break
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    canPrintMessage(messageTypeId) {
      const canPrint =
        messageTypeId === MessageTypes.QML_RequestForQuote ||
        messageTypeId === MessageTypes.QML_Quote ||
        messageTypeId === MessageTypes.QML_JobCancelled ||
        messageTypeId === MessageTypes.QML_PaymentAuthorised ||
        messageTypeId === MessageTypes.QML_PaymentDeclined ||
        messageTypeId === MessageTypes.QML_Error
      return canPrint && this.filter.messageStatusId === MessageStatusTypes.Read
    },
    canLoadMessage(messageTypeId) {
      const canLoad =
        (messageTypeId === MessageTypes.QML_RequestForQuote && this.autoSyncStatus !== 1 && this.autoSyncStatus !== 2) ||
        (messageTypeId !== MessageTypes.QML_RequestForQuote && this.autoSyncStatus !== 2)

      return this.filter.messageStatusId === MessageStatusTypes.UnRead && !this.$licenceExpired && canLoad
    },
    canResendMessage(messageStatusId) {
      const canResend =
        this.filter.messageStatusId === MessageStatusTypes.Sent ||
        (this.filter.messageStatusId === MessageStatusTypes.ToSend && messageStatusId === MessageStatusTypes.Failed)
      return canResend
    },
    async updateMessageStatus(messageId, messageStatusId) {
      try {
        await OrmMessageService.updateMessageStatus(messageId, messageStatusId)
      } catch (e) {
        this.$notification.openMessageXhrError('ORM Message', e)
      }
      this.getEntitySummaries()
      this.getCountSummaries()
    },
    markMessageAsRead(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.Read)
    },
    markMessageAsUnread(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
    },
    markMessageAsToSend(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
    },
    markMessageAsDeleted(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.Deleted)
    },
    unDeleteMessage(messageId, messageDirection) {
      if (messageDirection === 0) {
        // Incoming messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Read)
      } else {
        // Outgoing messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Sent)
      }
    },
    async addNew() {
      this.addStoreItem()
    },
    print(messageId, messageTypeId) {
      let reportName = ''
      let messageType = Object.keys(MessageTypes).find((k) => MessageTypes[k] === messageTypeId)
      switch (messageTypeId) {
        case MessageTypes.QML_RequestForQuote:
          reportName = 'rptOrmQuoteRequest'
          break
        case MessageTypes.QML_Quote:
          reportName = 'rptOrmAdjust'
          break
        case MessageTypes.QML_JobCancelled:
          reportName = 'rptOrmJobCancelled'
          break
        case MessageTypes.QML_PaymentDeclined:
          reportName = 'rptOrmPayDecline'
          break
        case MessageTypes.QML_PaymentAuthorised:
          reportName = 'rptOrmPaymentAdvice'
          break
        case MessageTypes.QML_Error:
          reportName = 'rptOrmErrorMsg'
          break
      }
      if (reportName) {
        const params = {
          CompanyID: this.$userInfo.companyId,
          MessageID: messageId
        }

        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)
        const emailer = new Emailer()
        emailer.remark = `ORM ${messageType}`
        emailer.remarkTypeId = RemarkTypes.Remark
        emailer.subject = `ORM ${messageType}`
        emailer.reportName = `ORM_${messageType}`
        StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: reportName },
          query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    previous() {
      this.currentStep--
      if (this.currentStep === this.steps.CreateAssign.id) {
        this.$v.loadOptions.quoteId.$reset()
      } else if (this.currentStep === this.steps.SelectInsurer.id) {
        this.$v.loadOptions.insurerId.$reset()
      } else if (this.currentStep === this.steps.UpdateCustomer.id) {
        this.$v.loadOptions.customerId.$reset()
      }
    },
    next() {
      switch (this.currentStep) {
        case Steps.CreateAssign.id:
          this.$v.loadOptions.quoteId.$touch()
          if (!this.$v.loadOptions.quoteId.$error || this.loadOptions.isCreateQuote) {
            this.currentStep++
          }
          break
        case Steps.SelectInsurer.id:
          this.$v.loadOptions.insurerId.$touch()
          if (!this.$v.loadOptions.insurerId.$error) {
            this.currentStep++
          }
          break
        case Steps.UpdateCustomer.id:
          this.$v.loadOptions.customerId.$touch()
          if (!this.$v.loadOptions.customerId.$error || this.loadOptions.customerOption === CustomerOptionTypes.Create) {
            this.currentStep++
          }
          break
        case Steps.OverwriteVehicle.id:
          break
      }
    },
    closeModal() {
      this.isLoadQuoteModalActive = false
      this.reset()
    },
    async loadMessage() {
      this.$showSpinner('Loading quote')
      try {
        this.isLoadQuoteModalActive = false
        this.isOrmMessageNoModalActive = false
        const result = await OrmMessageService.loadMessage(this.loadOptions)
        this.quoteId = result.data
        console.log(this.quoteId)
        this.getEntitySummaries()
        this.getCountSummaries()
        if (Guid.validGuid(this.quoteId)) {
          this.quoteNo = await OrmMessageService.getQuoteNo(this.quoteId)
          this.print(this.loadOptions.messageId, this.loadOptions.messageTypeId)
          if (this.loadOptions.isCreateQuote && this.loadOptions.messageTypeId === MessageTypes.QML_RequestForQuote) {
            this.createAuditTrail(this.quoteId, 'insert', '')
          } else {
            // TODO: create update AuditTrail entry
          }
          this.savedLoadOptions = _cloneDeep(this.loadOptions)
          this.isConfirmModalActive = true
        }
      } catch (e) {
        throw e
      } finally {
        this.reset()
        this.$hideSpinner()
      }
    },
    reset() {
      this.loadOptions.messageId = Guid.empty()
      this.loadOptions.messageTypeId = MessageTypes.QML_RequestForQuote
      this.loadOptions.quoteId = Guid.empty()
      this.loadOptions.isCreateQuote = true
      this.loadOptions.insurerId = Guid.empty()
      this.loadOptions.customerOption = CustomerOptionTypes.Create
      this.loadOptions.isOverwriteVehicle = false
      this.loadOptions.customerId = Guid.empty()
      this.loadOptions.title = ''
      this.loadOptions.customerName = ''
      this.loadOptions.firstname = ''
      this.loadOptions.lastname = ''
      this.loadOptions.isPerson = false
      this.loadOptions.regoNo = ''
      this.loadOptions.vehicleId = Guid.empty()
      this.loadOptions.isOverwriteOrmMessageNo = false
      this.currentStep = Steps.CreateAssign.id
      this.selectedMessage = null
      // Reset validation objects
      this.$v.loadOptions.$reset()
    },
    getEntityWithMinimumMessageNo(entities) {
      const entity = entities.reduce((prev, current) => {
        return prev.ormMessageNo < current.ormMessageNo ? prev : current
      })
      return entity
    },
    // get the entity with the minimum message no in the page context
    getMinimumMessageNoEntityIndex(){
      let res = 0
      for (let i = 0; i < this.entitySummaries.length; i++) {
        if(this.entitySummaries[i].ormMessageNo < this.entitySummaries[res].ormMessageNo){
            res = i
        }
      }
     return res
    },
    getRequestsInSameClaim() {
      return this.entitySummaries.filter(x => x.claimNo === this.selectedMessage.claimNo
        && x.regoNo === this.selectedMessage.regoNo
        && x.reinstatementNo === this.selectedMessage.reinstatementNo
        && x.messageId !== this.selectedMessage.messageId)
    },
    async load(index) {
      this.isEarlierMessageModalActive = false

      // get message in given index
      // entity summaries is an array
      this.selectedMessage = this.entitySummaries[index]
      // get a list of requests in the same claim
      const requestsInSameClaim = this.getRequestsInSameClaim()

      // if there are more than one request in the same claim
      // get the entity with the minimum message no
      // and set the selectedMessage to that entity
      if(requestsInSameClaim.length > 0) {
        // get the entity with the minimum message no with same rego and claim number in the page context
        // const minimumMessageNoEntityIndex = this.getMinimumMessageNoEntityIndex()
        const mininumMessage = this.getEntityWithMinimumMessageNo(requestsInSameClaim)

        // if current selected message is not the minimum message no entity
        if(this.selectedMessage.ormMessageNo > mininumMessage.ormMessageNo){
          // asign state to the minimum message no entity
          this.minimalMessageNoIndex = mininumMessage.rowNumber - 1
          // open up the confirmation dialog
          this.isEarlierMessageModalActive = true
          return
        }
      }

      // Set loadOptions properties based on the selectedMessage's properties
      this.loadOptions.messageId = this.selectedMessage.messageId
      this.loadOptions.messageTypeId = this.selectedMessage.messageTypeId
      this.loadOptions.regoNo = this.selectedMessage.regoNo

      // Check the messageTypeId to determine the next steps

      // If the message type is a request for quote, perform additional actions
      if (this.selectedMessage.messageTypeId === MessageTypes.QML_RequestForQuote) {
        this.assertVehicleExists(this.selectedMessage.regoNo)
        this.loadOptions.isPerson = this.selectedMessage.isPerson
        this.loadOptions.customerName = this.selectedMessage.customerName

        // If the customer is a person, extract the last name, first name, and title from the customerName
        if (this.selectedMessage.isPerson) {
          const names = this.selectedMessage.customerName.split(', ')
          this.loadOptions.lastname = names[0]
          this.loadOptions.firstname = names.length > 1 ? names[1] : ''
          this.loadOptions.title = this.selectedMessage.title
        }
        this.isLoadQuoteModalActive = true
      } else if (
        this.selectedMessage.messageTypeId === MessageTypes.QML_PaymentAuthorised ||
        this.selectedMessage.messageTypeId === MessageTypes.QML_PaymentDeclined ||
        this.selectedMessage.messageTypeId === MessageTypes.QML_JobCancelled ||
        this.selectedMessage.messageTypeId === MessageTypes.QML_Quote
      ) {
        this.loadOptions.quoteNo = this.selectedMessage.quoteNo
        if (this.selectedMessage.expectedOrmMessageNo !== this.selectedMessage.ormMessageNo) {
          // Prompt overwrite MessageNo
          this.isOrmMessageNoModalActive = true
        } else {
          this.loadMessage()
        }
      } else {
        this.loadMessage()
      }
    },
    async assertVehicleExists(rego) {
      this.vehicleExists = await OrmMessageService.assertVehicleExists(rego)
      this.steps.OverwriteVehicle.enabled = this.vehicleExists
    },
    async gotoQuote(goto) {
      this.isConfirmModalActive = false
      if (goto) {
        const accessModel = await NavService.getAccess('A077', 'detail')
        let url = ''
        if (accessModel.version === '2') {
          const routeData = this.$router.resolve({
            name: QuoteRoutes.QuoteHeader.name,
            params: { quoteId: this.quoteId }
          })
          url = routeData.href
        } else {
          url = `${process.env.VUE_APP_ROOT_URI}/modules/handlers/QuoteLoading.ashx?quoteId=${this.quoteId}&mode=5`
        }
        window.open(url, '_blank')
      }
    },
    overwriteOrmMessageNo(overwrite) {
      this.loadOptions.isOverwriteOrmMessageNo = overwrite
      this.isOrmMessageNoModalActive = false
      if (this.loadOptions.isOverwriteOrmMessageNo) {
        this.loadMessage()
      }
    },
    async createAuditTrail(entityId, operationType, deepdiff) {
      const quote = await OrmMessageService.getQuoteEntity(entityId)
      const audtiTrailItem = {
        auditTrailId: null,
        performedAt: new Date().toISOString,
        userId: this.$userInfo.userId,
        userName: this.$userInfo.displayName,
        userRole: this.$userInfo.isSupportUser ? 'Support' : 'Normal',
        userAgent: '',
        userHostAddress: '',
        userHostName: '',
        url: '',
        companyId: this.$userInfo.companyId,
        entityId: entityId,
        entityType: 'Quote',
        entityReference: `${quote.prefix}${quote.quoteNo}${quote.suffix}`,
        operationType: operationType,
        deepdiff: operationType === 'update' ? JSON.stringify(deepdiff) : null,
        entity: operationType === 'insert' ? JSON.stringify(quote) : null
      }
      // console.log(audtiTrailItem)
      OrmMessageService.postAuditTrailItem(audtiTrailItem)
    },
    showMessageActionConfirmModal(messageId, messageStatusTypeId) {
      this.messageOperationTypeId = messageStatusTypeId
      this.selectedMessageId = messageId
      this.isMessageOperationConfirmModalActive = true
    },
    changeMessageStatus(messageId, messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          this.updateMessageStatus(messageId, MessageStatusTypes.Read)
          break
        case MessageStatusTypes.Unread:
          this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
          break
        case MessageStatusTypes.ToSend:
          this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
          break
        case MessageStatusTypes.Deleted:
          this.updateMessageStatus(messageId, MessageStatusTypes.Deleted)
          break
      }
      this.isMessageOperationConfirmModalActive = false
    },
    getFolderName(messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          return 'Read'
          break
        case MessageStatusTypes.Unread:
          return 'Unread'
          break
        case MessageStatusTypes.ToSend:
          return 'To Send'
          break
        case MessageStatusTypes.Deleted:
          return 'Deleted'
          break
      }
    }
  },
  beforeRouteLeave: function (to, from, next) {
    if (to.name === OrmMessageRoutes.OrmMessageView.name) {
      const messageId = to.params.messageId
      const message = this.entitySummaries.find((m) => m.messageId === messageId)
      this.setMessageSummary(message)
    }
    next()
  }
}
</script>
