import { render, staticRenderFns } from "./OrmMessageListSideMenu.vue?vue&type=template&id=0b6c767b&scoped=true"
import script from "./OrmMessageListSideMenu.vue?vue&type=script&lang=js"
export * from "./OrmMessageListSideMenu.vue?vue&type=script&lang=js"
import style0 from "./OrmMessageListSideMenu.vue?vue&type=style&index=0&id=0b6c767b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6c767b",
  null
  
)

export default component.exports